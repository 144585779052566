.grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.gridItem{
    padding:30px;
}
#search{
    background: #5f5f5f;
}

h4{
    color:white;
    text-align: left;
}
#searchButtons{
    padding: 0.25in;
    text-align: right;
}
input, select, button {
    padding: 5px;
    border-radius: 5px;
}
#clear{
    height: 3em;
    width: 6em;
    background: transparent;
    border: 2px solid white;
    color:white;
    font-family: monospace;
}
#card{
    height: 3em;
    width: 15em;
    background: transparent;
    border: 2px solid white;
    color:white;
    font-family: monospace;
}
#clear:hover{
    height: 3em;
    width: 6em;
    background: black;
    border: 2px solid white;
}
#card:hover{
    height: 3em;
    width: 15em;
    background: black;
    border: 2px solid white;
}
.elementListItem{
    padding: 0px;
    display: table-cell;  
}
.fas{
    padding: 10px;
    border: 1px solid;
    background: white;
    text-align: center;
    width: .2in;
  }
  #fire{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  #dark{
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  #searchButtons > button{
    margin: 15px;
  }