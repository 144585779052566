.container{
    background: #5f5f5f;
    margin: 3rem auto 3rem auto;
    padding: 1rem;
    border: 0.5rem solid #333;
    border-style: outset;
    display: flex;
    justify-content: space-between;
    height: 10rem;
    max-width: 40rem;
}

.image{
    height: inherit;
}

h2{
    color: white;
    font-size: xx-large;
}

.anchor{
    height: inherit;
    text-decoration: none
}

.container > div{
    text-align: left;
}