#pic{
  width: auto;
  height:auto;
  border-radius: 1.4rem;
}
.gridContainer{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 2fr 1fr;
  border-radius: 15px;

}
h3{
  display: inline;
  color:white;
  font-family: sans-serif;
}
p{
  display: inline;
  color:white;
  font: menu;
  size: 2em;
}
.gridItem div{
  padding: 10px;
}
:global(.icon){
  display: inline-block;
  background-size: contain;
  height: 25px;
  width: 25px;
  line-height: 22px;
  margin: 2px 3px;
  border: 1px solid #111;
  font-size: 14px;
  font-weight: 700;
  color: #111;
  border-radius: 100%;
  vertical-align: middle;
  text-align: center;
  background-color: white;
}
:global(.num){
  color: #111;
  font-size: 20px;
}
:global(.fire){
  background-image: url(/icons/fire.png);
}
:global(.ice){
  background-image: url(/icons/ice.png);
}
:global(.wind){
  background-image: url(/icons/wind.png);
}
:global(.earth){
  background-image: url(/icons/earth.png);
}
:global(.lightning){
  background-image: url(/icons/lightning.png);
}
:global(.water){
  background-image: url(/icons/water.png);
}
:global(.light){
  background-image: url(/icons/light.png);
}
:global(.dark){
  background-image: url(/icons/dark.png);
}
:global(.down){
  background-image: url(/icons/downarrow.png);
  border-radius: 15%;
}
:global(.exburst){
  color: #332a9d;
  font-family: arial black,sans-serif;
  display: contents;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  font-style: italic;
}
:global(.ability){
    color: #f80;
    font-family: arial black,sans-serif;
    display: contents;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    font-style: italic;
}
:global(.s){
    color: red;
    font-size: 20px;
    border-radius: 15%;
}
.gridItem{
  color:white;
  border-width: 3px;
  border-style: solid double solid double;
  border-radius: 15px;
  margin: 5px;
}
.flex{
  flex: 50%;
}
#leftflex{
  text-align: end;
}
#flexBox{
  display: flex;
}
#report{
  background: red;
  border: 3px solid white;
  color: white;
  width: 5rem;
  height: 2rem;
}
#item2{
  text-align: left;
  width: 4in;
  height: fit-content;
}
#item2{
  text-align: left;
  width: 4in;
  height: fit-content;
}
#item3{
  text-align: left;
  width: 4in;
  height: fit-content;
  min-height: 6rem;
}
#item4{
  text-align: left;
  width: 4in;
  height: fit-content;
  min-height: 6rem;
}
#item5{
  text-align: left;
  width: 4in;
  height: fit-content;
  min-height: 6rem;
}

#item5 > h3{
  text-align: center;
  display: block;
}

#item4 > h3{
  text-align: center;
  display: block;
}

#tcg_button{
  width: -webkit-fill-available;
  background: #4481c3;
  color: white;
  margin: 11px;
  border: none;
}

#tcg_button:hover {
  background: #5099e7;
}

#tcg_button:active {
  background: #4481c3;
}

#add_card{
  width: -webkit-fill-available;
  background: #4f8a4f;
  color: white;
  margin: 11px;
  border: none;
}

#add_card_disabled{
  width: -webkit-fill-available;
  background: #797979;
  color: white;
  margin: 11px;
  border: none;
}

#add_card:hover{
  background: #60a860;
}
#add_card:active{
  background: #4f8a4f;
}

#tcg_anchor{
  width: inherit;
}
#reportBox{
  text-align: right;
}
#graphdiv{
  width: 50%;
  margin: auto;
  min-width: 400px;
}