.flexBox{
    display: flex;
    margin: 1rem auto 1rem auto;
    justify-content: left;
    border-color: white;
    border-style: solid double solid double;
    border-width: 3px;
    border-radius: 15px;
    max-width: 50rem;
}
.pic{
    order:1;
    height: 300px;
    border-radius: 8px;
}
.value{
    order:2;
}
h1, h2{
    color:white;
}
.super{
    vertical-align:super;
    display:inline;
    font-size: 1rem;
}
