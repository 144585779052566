#SearchResults{
    background:black;
    color:white;
}
.grid{
    display: grid;
    grid-template-columns: 5% auto auto auto auto auto 5%;
    grid-template-rows: 0.5in auto auto auto auto auto 0.5in;
}

.img_result{
    height: 300px;
    padding: 0.5rem;
    border-radius: 0.8rem;
}

ul.pagerList > li{
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: 1px solid black;
    line-height: 25px;
    color:black;
}

.pager{
    background: #4f8a4f;
    text-align: left;
}
.pagination{
    height: 30px;
    width: 30px;
    background-color: transparent;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: 2px solid white;
    line-height: 30px;
    color: white;
    font-family: monospace;
    margin: 0.2rem;
}
.pagination:hover{
    background: black;
    cursor: pointer;
}