
#nav a {
  float: left;
  font-size: 25px; 
}
#nav li a {
  color: white;
  padding: 14px 16px;
  text-decoration: none; 
}
#nav li a:hover {
  background-color: #111; 
}