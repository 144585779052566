#sign_in{
    text-align: right;
    color: white;
    background: black;
}
.modal{
    top: -50%;
    left: 50%;
    position: fixed;
    background: black;
    padding: 9rem;
    z-index: 1;
    border: 1rem solid white;
    border-radius: 1rem;
    box-shadow: 10px 10px #0000008c;
    /* display: none; */
    transition: top 0.7s;
    transform: translate(-50%, -50%);
}
.tran{
    top: 50%;
}